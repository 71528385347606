import { Button, Center, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdKeyboardCommandKey, MdOutlineAutoAwesome } from "react-icons/md"

export default function HomeEmptyView() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const phoneWidth = 480

    useEffect(() => {
        window.addEventListener("resize", screenWidthUpdated);
	})

    const screenWidthUpdated = () => {
        setScreenWidth(window.innerWidth)
    }

    const Prompt = (text: string) => {
        return <Text 
                    width={300}
                    textAlign={'center'} 
                    fontSize={"rem"} 
                    noOfLines={2}
                    bgColor='secondaryGray.100' 
                    whiteSpace={'pre-line'}
                    padding={"10px"} 
                    borderRadius={5}>
                        "{text}"
                </Text>
    }

    return (
		<Flex direction="column" position="relative" mt={150}>
            <Center>
                <HStack spacing={[50, 50, 75]}>
                    <VStack spacing={10}>
                        <VStack>
                            <VStack>
                                <MdKeyboardCommandKey size={50} />
                                <Text as='b' fontSize={'lg'}>Examples</Text>
                            </VStack>
                        </VStack>
                        { screenWidth > phoneWidth && 
                            <VStack spacing={5}>
                                { Prompt("Can you help me \nformat and write this grant?") }
                                { Prompt("How can I contribute \nto G4GC?") }
                                { Prompt("What happens if I don't format \nthe grant application correctly?") }
                            </VStack>
                        }

                        { screenWidth <= phoneWidth && 
                            <VStack spacing={5}>
                                { Prompt("What is my first day \ngoing to be like?") }
                                { Prompt("How do I deal \nwith imposter syndrome?") }
                            </VStack>
                        }
                        
                    </VStack>
                    
                    <VStack spacing={10} display={{ base: 'none', md: 'block'}}>
                        <VStack>
                            <VStack>
                                <MdOutlineAutoAwesome size={50} />
                                <Text as='b' fontSize={'lg'}>Capabilities</Text>
                            </VStack>
                        </VStack>
                        <VStack spacing={5}>
                        { Prompt("Assist with writing \ngrants for G4GC") }
                            { Prompt("Allow you to mark a \nresponse as incorrect") }
                            { Prompt("Trained to decline questions not \nrelated to G4GC grant writing") }
                        </VStack>
                    </VStack>
                </HStack>
            </Center>
            <Center mt="20">
                <Text fontSize="2xl" fontWeight={"bold"} textAlign='center'>
                    Use the "New chat" button to start a conversation!
                </Text>
            </Center>
		</Flex>
	)
}